.sidebar .nav {
    padding: 0px !important;
}

.sidebar ul li:hover {
    background-color:darkolivegreen;
}

.nav-item {
    text-shadow: beige;
}

li {
    text-align: left;
}
