.listCardContainer {
    /* === Sizing === */
    align-self: center;
    
    height: auto !important; /* real browsers */
    max-height: 500px; /* real browsers */
    width: 350px;
    perspective: 1000px;
  }

  .button-container {
    padding-top: 10px;
  }
  
  .card-container {
    transition: transform 0.3s ease; /* Adjust the duration and easing as needed */
    transition-delay: 0.2s; /* Add the desired delay here */
  }
