.App {
  justify-content: center;
  align-items: center;
  
}
.App1 {
  /* === Sizing === */
  width: 100vw;
  height: 100vh;

  /* === Content Alignment === */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 500px) {
  .h-sm-100 {
      height: 100%;
  }
}

/* ButtonRow.css */
.button-container {
  display: flex;
  padding-left: 10px;
  padding-right: 15px;
  justify-content: space-between; /* This will push the buttons to the left and right */
  align-items: center; /* Vertically center the buttons */
}

.left-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
}

.right-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
}

.navbar {
  --bs-navbar-toggler-border-color: rgba(0,0,0,0);
  --bs-navbar-disabled-color: rgba(0,0,0,0);
}
