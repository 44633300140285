.card {
  /* Sizing */
  height: 100%;
  width: 90%;
  
  transform-style: preserve-3d;

  /* Content Alignment */
  position: relative;

  /* Styling */
  border-radius: 3px;
  border: 3px solid white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);

  user-select: none;
  cursor: pointer;
}

.card-front,
.card-back {
  /* Sizing */
  height: 100%;
  width: 100%;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  /* Alignment */
  position: absolute;

  /* Content Alignment */
  
  justify-content: center;
  align-items: center;

  /* Content Styling */
  font-family: Montserrat;
  font-weight: 700;
  font-size: 2.1rem;
  color: black;
  /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9); */
}

.card-front {
  display: flex;
  background-color: #73d0ff;
}

.card-back {
  position: absolute;
  background-color: #ffad66;
  transform: rotateY(180deg);
  overflow-y: auto;
}


.backWord {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 5px;
  font-size: 2.0rem;
  color: green;
  display: flex;
  padding-left: 5px;
  
}

.backMeaning {
  padding-left: 4px;
  padding-top: 2px;
  padding-right: 4px;
  line-height: 17px;
  font-size: 1.1rem;
  font-style: italic;
  text-align: center;
}

.backExamples {
  padding-left: 7px;
  padding-bottom: px;
  padding-top: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  color: blue;
  text-align: left;
}

.example {
  padding-left: 7px;
  padding-bottom: 5px;
  padding-right: 2px;
  font-size: 0.93rem;
  font-style: italic;
  font-weight: bold;
  text-align: left;
}

u {
  color: 'blue'
}

.exampleHighlight {
  color: maroon;
  text-decoration: underline;
  font-size: 1.1rem;
}

