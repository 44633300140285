@media all and (min-width: 480px) {
    .Login {
      padding: 80px 10px;
    }
  
    .Login {
      margin: 0 auto;
      max-width: 480px;
      align-items: center;
      color: white;
      border: 2px;
      border-color: aqua;

    }
  }

  .Login form a {
    margin-bottom: 15px;
    display: block;
    font-size: 14px;
  }  

  .form-control {
    line-height: 0.7
  }
