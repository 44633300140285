.MyAccount .lander {
  padding: 80px 0;
  text-align: center;
  color: white;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 360;
}

.btn-group-sm>.btn, .btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}
